@font-face {
  font-family: "Bebas Neue";
  font-style: "normal";
  font-weight: 400;
  src: local("Bebas Neue Regular"),
    url(../../utils/font/BebasNeue-Regular.woff) format("woff");
}

@font-face {
  font-family: "Bebas Neue";
  font-style: "normal";
  font-weight: 700;
  src: local("Bebas Neue Bold"),
    url(../../utils/font/BebasNeue-Bold.woff) format("woff");
}

@media (max-width: 1000px) {
  .project-preview-image-wrapper {
    grid-row: 2 !important;
  }
  .project-preview {
    grid-template-columns: 1fr !important;
  }
  .project-preview-col._first {
    grid-gap: 1rem;
  }
}

.project-preview {
  font-weight: 400;
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
  grid-gap: 3rem;
  margin-bottom: 8rem;
}

.project-preview:nth-child(even) {
  direction: rtl;
}

.project-preview-col._first {
  direction: ltr;
  border-radius: 1rem;
  display: grid;
  padding: 3rem;
}

.project-preview-col._second {
  padding: 3rem 0;
}

.project-preview-heading {
  z-index: 0;
  font-size: 1.5rem;
  font-weight: 700;
  grid-column: 1;
}

.project-preview-emoji {
  display: inline;
  margin-left: 0.5rem;
}

.project-preview-name {
  display: inline;
  position: relative;
}

.project-preview .project-preview-name::before {
  position: absolute;
  content: "";
  z-index: -1;
  top: 50%;
  left: 0;
  height: 50%;
  transition: width 0.5s;
  width: 0;
  background: var(--accent);
}

.project-preview:hover .project-preview-name::before {
  width: 100%;
}

.project-preview-description {
  margin-bottom: 1rem;
  grid-column: 1;
}

.project-preview-link {
  font-size: 0.8rem;
  height: 1rem;
  padding: 0.5rem 0;
  width: min-content;
}

.project-preview-link:hover {
  text-decoration: underline;
}

.project-preview-image-wrapper {
  grid-column: 2;
  grid-row: 1/4;
  position: relative;
  transform: scale(0.95);
  transition: transform 0.5s;
}

.project-preview:hover .project-preview-image-wrapper {
  transform: scale(1);
}

.project-preview-image {
  width: 100%;
}

.project-preview-image2 {
  position: absolute;
  border-radius: 2px;
  top: 6%;
  left: 13.5%;
  width: 73%;
  max-height: 81.3%;
  object-fit: cover;
  object-position: 0 0;
}
