@media (max-width: 700px) {
  #header-nav-wrapper {
    display: none !important;
  }

  #menu {
    display: inline-block !important;
  }
}

@media (max-width: 300px) {
  #header-logo-text {
    display: none !important;
  }
}

#header {
  z-index: 10;
  position: fixed;
  width: 100%;
  user-select: none;
  color: var(--text);
  box-shadow: -16.2px 37.5px 75px -40px rgba(0, 0, 0, 0.3);
}

#header::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--background);
  opacity: 0.9;
}

#header-container {
  padding: 0 2rem;
  height: var(--header-height);
}

#header-content {
  max-width: var(--page-width);
  margin: auto;
  vertical-align: top;
  position: relative;
  white-space: nowrap;
}

#header-logo-wrapper {
  display: inline-block;
}

#header-logo-wrapper div {
  display: inline-block;
}

#header-logo {
  height: var(--header-height);
  width: 3rem;
  mask-image: url("../../utils/images/pebble-p.png");
  mask-size: 50%;
  mask-position: 0 center;
  mask-repeat: no-repeat;
  background: var(--text);
  display: inline-block;
}

#header-logo-text {
  vertical-align: top;
  line-height: var(--header-height);
}

#header-nav-wrapper {
  position: absolute;
  right: 0;
  display: inline-block;
}

.header-nav {
  display: inline-block;
  margin-left: 3rem;
  line-height: var(--header-height);
  height: var(--header-height);
}

.header-nav:hover {
  font-weight: 700;
}

.header-nav.active {
  font-weight: 700;
  border-bottom: 5px solid var(--text);
}
