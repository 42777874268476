.home-section {
  position: relative;
  padding: 3rem 0;
}

.home-section-background {
  top: -2rem;
  height: 5rem;
  transform: skewY(-5deg);
}

.home-section-header {
  margin-bottom: 5rem;
}

.home-section-title {
  font-weight: 700;
  font-size: 3rem;
}

.home-section-link {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
}

/* banner */
#home-banner-background {
  height: 12rem;
  bottom: -2rem;
  background: var(--text);
  transform: skewY(-5deg);
}

#home-banner-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

#home-banner-text {
  overflow: hidden;
  font-size: 1.5rem;
}

.home-banner-loop {
  font-weight: 700;
  padding-bottom: 0.5rem;
  border-bottom: 5px solid var(--text);
}

/* services */
@media (max-width: 700px) {
  .home-service {
    grid-auto-flow: row !important;
  }

  .home-service-image {
    width: 70% !important;
    margin-left: 15% !important;
  }

  .home-service-title {
    margin: 0 0 1rem !important;
  }
}

#home-services-background {
  background: var(--accent);
  opacity: 0.5;
}

.home-service {
  position: relative;
  scroll-padding: 10rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3rem;
  margin-bottom: 8rem;
  grid-auto-columns: 1fr 1fr;
}

.home-service:nth-child(even) {
  direction: rtl;
}

.home-service-col {
  direction: ltr;
}

.home-service-title {
  font-size: 1.5rem;
  margin: 2rem 0;
  font-weight: 700;
}

.home-service-text {
  margin-bottom: 2rem;
}

.home-service-contact {
  text-decoration: underline;
}

.home-service-col._image {
  perspective: 1000px;
  transition: perspective 0.5s;
}

.home-service:hover .home-service-col._image {
  perspective: 500px;
}

.home-service-image {
  width: 100%;
  transform-style: preserve-3d;
  box-shadow: var(--box-shadow);
  border-radius: 1rem;
}

.home-service:nth-child(odd) .home-service-image {
  transform: rotate3d(0.3, -0.866, 0, 15deg) rotateZ(1deg);
}

.home-service:nth-child(even) .home-service-image {
  transform: rotate3d(0.3, 0.866, 0, 15deg) rotateZ(-1deg);
}

/* expertise */
@media (max-width: 700px) {
  .home-expertise-topics {
    grid-auto-flow: row !important;
  }
}

#home-expertise-background {
  transform: skewY(5deg);
  top: 4rem;
  background: var(--background2);
  opacity: 0.9;
}

#home-expertise-background2 {
  transform: skewY(5deg);
  z-index: -4;
  top: 2rem;
  background: var(--text);
}

.home-expertise-panel {
  position: relative;
  margin-bottom: 0rem;
}

.home-expertise-title {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 3rem;
}

.home-expertise-topics {
  display: grid;
  grid-gap: 3rem;
  grid-auto-flow: column;
}

.home-expertise-topics._webtech {
  grid-template-rows: 1fr 1fr;
}

.home-expertise-topic-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  max-width: 100%;
}

.home-expertise-topic-icon {
  height: 1.5rem;
  width: 1.5rem;
}

/* projects */
#home-projects-background {
  top: -1rem;
  background: var(--background2);
  opacity: 0.9;
}

#home-projects-background2 {
  z-index: -4;
  top: -3rem;
  background: var(--accent);
  opacity: 0.5;
}

#home-projects-carousel {
  margin-top: -20rem;
  padding-top: 20rem;
  margin-bottom: -15em;
  padding-bottom: 20rem;
}

#home-projects-button {
  margin: -3rem auto 5rem;
}

/* CTA */
#home-cta-background {
  background: var(--background2);
  z-index: -2;
}

#home-cta-background2 {
  top: 12rem;
  transform: unset;
  transform: skewY(5deg);
  background: var(--accent);
  opacity: 0.5;
  z-index: -1;
}

#home-cta-background3 {
  top: 16rem;
  height: 18rem;
  transform: unset;
  transform: skewY(5deg);
  background: var(--text);
}

#home-cta-panel {
  padding: 5rem;
}

.home-cta-text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.home-cta-text._first {
  font-weight: 700;
}
