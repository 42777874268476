#projects-banner-background {
  height: 12rem;
  bottom: -2rem;
  background: var(--text);
  transform: skewY(-5deg);
}

#projects-banner-background2 {
  bottom: -6rem;
  height: 5rem;
  background: var(--accent);
  opacity: 0.5;
  transform: skewY(-5deg);
}

#projects-banner {
  margin-bottom: 5rem;
}

#projects-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

#projects-banner-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

#projects-banner-text {
  overflow: hidden;
  font-size: 1.5rem;
}
