.input {
  padding-top: 1.5rem;
  position: relative;
  cursor: text;
  margin-bottom: 3rem;
}

.input-name {
  position: absolute;
  color: var(--text);
  transition: all 0.5s;
  top: 1.5rem;
}

.input-name.focus {
  top: 0;
  font-size: 0.8rem;
}

.input-field {
  font-family: "Space Grotesk", sans-serif;
  padding: 0;
  width: 100%;
  line-height: 2rem;
  border: 0;
  outline: 0;
  box-sizing: content-box;
  border-bottom: 3px solid var(--text);
  background: none;
  color: var(--text);
  font-size: 1.2rem;
}

.textarea-field {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.2rem;
  padding: 0;
  display: block;
  margin: auto;
  width: 100%;
  line-height: 2rem;
  border: 0;
  box-sizing: content-box;
  border-bottom: 3px solid var(--text);
  background: none;
  color: var(--text);
  resize: none;
  overflow-y: hidden;
  outline: 0;
}
