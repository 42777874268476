@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;800&display=swap");

body {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

:root {
  --page-width: 1000px;
  --content-width: 500px;
  --header-height: 6rem;
  --footer-background: #2d3440;
  --footer-color: #d2cbbf;
  color: var(--text);
  background: var(--background);
  --box-shadow: -27.1px 62.5px 125px -25px rgba(50, 50, 93, 0.5),
    -16.2px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
}

/* light */
:root {
  --background: #f9f9f7;
  --background2: #f4f2ef;
  --background3: #bbb;

  --text: #2d3440;
  --accent: orange;
  --accent-opaque: #fccf7c;
  --accent2: blue;
}

::selection {
  background-color: var(--accent-opaque);
}

a {
  text-decoration: none;
  color: var(--text);
}

#container {
  position: relative;
}

.logo {
  background: var(--text);
  mask-image: url("./utils/images/pebble-p.png");
  mask-size: auto 100%;
  mask-repeat: no-repeat;
  height: 4rem;
}

.page {
  padding: 2rem;
  padding-top: calc(var(--header-height) + 5rem);
  min-height: 100vh;
}

.content {
  max-width: var(--page-width);
  margin: auto;
}

.banner {
  position: relative;
  margin-bottom: 3rem;
}

.panel {
  max-width: var(--page-width);
  margin: auto;
  background: var(--background2);
  padding: 3rem;
  margin-bottom: 3rem;
  border-radius: 1rem;
  box-shadow: var(--box-shadow);
}

.background {
  position: absolute;
  left: -2rem;
  right: -2rem;
  z-index: -1;
}

.button {
  user-select: none;
  text-transform: uppercase;
  border-radius: 3rem;
  font-weight: 700;
  border: 3px solid var(--text);
  line-height: 3rem;
  text-align: center;
  outline: none;
  max-width: var(--content-width);
  margin: auto;
}

.button:hover,
.button:focus {
  cursor: pointer;
  background: var(--text);
  color: var(--background);
}

.submit-error {
  color: var(--accent);
  font-weight: 700;
  margin-bottom: 3rem;
}

.no-break {
  white-space: nowrap;
}

.underline {
  z-index: 2 !important;
  width: max-content;
  position: relative;
  cursor: pointer;
}

.underline::before {
  z-index: -1;
  display: inline-block;
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  transition: width 0.5s;
  left: 0;
  height: 50%;
  background: var(--accent);
}

.underline:hover::before {
  width: 100%;
}
