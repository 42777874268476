@media (max-width: 700px) {
  .contact-content-col:first-child {
    grid-column: 1/3;
  }
  .contact-content-col:last-child {
    display: none;
  }
}

#contact-background {
  left: 0;
  right: 0;
  top: 10rem;
  bottom: 5rem;
  background: var(--background2);
  transform: skewY(-5deg);
}

#contact-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

#contact-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

#contact-subtitle {
  margin-bottom: 3rem;
}

.contact-content-col {
  padding-bottom: 3rem;
}

#contact-pebble {
  width: 100%;
  height: 100%;
  background: var(--text);
  mask-image: url("../../utils/images/pebble-p-3d.png");
  mask-size: 20%;
  mask-repeat: no-repeat;
  mask-position: 50% 15rem;
}
