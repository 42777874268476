#footer {
  padding: 0 2rem;
  user-select: none;
  background: var(--footer-background);
  color: var(--footer-color);
}

#footer-container {
  align-items: flex-start;
  display: flex;
  max-width: var(--page-width);
  padding-top: 3rem;
  margin: auto;
  height: auto;
}

.footer-col {
  vertical-align: top;
  display: inline-block;
  margin-right: 4rem;
  margin-bottom: 2rem;
}

/* .footer-col:last-child {
  margin-right: 2rem;
} */

#footer-logo {
  height: 2rem;
  min-width: 5rem;
  mask-image: url("../../utils/images/pebble-p-3d.png");
  mask-size: contain;
  mask-repeat: no-repeat;
  background: var(--footer-color);
  display: inline-block;
  color: var(--footer-color) !important;
}

.footer-col-wrapper {
  flex-wrap: flex;
}

.footer-col-link {
  color: var(--footer-color);
  text-transform: capitalize;
  display: block;
  font-size: 0.8rem;
  margin-top: 0.8rem;
}

.footer-col-link._projects {
  text-transform: none;
}

.footer-col-title {
  font-weight: 700;
}

.footer-col-link:hover {
  cursor: pointer;
  font-weight: 700;
}
