@media (max-width: 1300px) {
  #overlay {
    display: none !important;
  }
}

/* #cursor {
  position: fixed;
  height: 3rem;
  width: 3rem;
  transform: translate(-50%, -50%);
} */

#socials {
  position: fixed;
  left: calc((100vw - var(--page-width)) / 2);
  margin-left: -5rem;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
}

.socials-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 1rem;
  backdrop-filter: invert(1);
  mask-size: 100%;
}

.socials-icon:hover {
  background: var(--background3);
}

#socials-li {
  mask-image: url("./utils/images/li.png");
}

#socials-fb {
  mask-image: url("./utils/images/fb.png");
}

#socials-tw {
  mask-image: url("./utils/images/tw.png");
}

#socials-ig {
  mask-image: url("./utils/images/ig.png");
}

#copyright {
  color: var(--footer-color);
  font-size: 0.8rem;
  right: calc((100vw - var(--page-width)) / 2);
  margin-right: -10rem;
  bottom: 3rem;
  position: fixed;
}
