.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
}

.team-member {
  margin-bottom: 2rem;
  text-align: center;
}

.team-member-name {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.team-member-role {
  font-size: 1.2rem;
}

.team-member-image {
  margin: auto;
  margin-bottom: 1rem;
  height: 15rem;
  width: 15rem;
  border-radius: 10rem;
  overflow: hidden;
}

.team-member-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.team-member-image._Gabe img {
  transform: scale(2) translateY(-1rem);
  object-position: left center;
}
