@media (max-width: 700px) {
  #project-modal-grid {
    padding: 5rem 2rem !important;
  }
}

#project-modal {
  z-index: 20;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

#project-modal-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 1rem;
  top: 50%;
  transform: translateY(-50%);
}

#project-modal-container {
  position: relative;
  border-radius: 1rem;
  max-width: var(--page-width);
  margin: 0 auto;
  max-height: 80vh;
  overflow-y: auto;
}

#project-modal-close {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

#project-modal-grid {
  padding: 5rem;
  grid-gap: 3rem;
  display: grid;
}

#project-modal-title {
  font-size: 2rem;
  margin-bottom: -2rem;
  font-weight: 700;
}

#project-modal-type {
  display: inline;
}

#project-modal-link {
  display: inline;
  margin-left: 0.5rem;
}

#project-modal-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.project-modal-row {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 3rem;
}

.project-modal-row:nth-child(odd) {
  direction: rtl;
}

.project-modal-column {
  direction: ltr;
}

.project-modal-column-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.project-modal-column-text {
}

.project-modal-image-wrapper {
  max-width: var(--content-width);
  margin: auto;
  position: relative;
  transition: transform 0.5s;
}

.project-modal-image {
  width: 100%;
}

.project-modal-image2 {
  position: absolute;
  border-radius: 2px;
  top: 6%;
  left: 13.5%;
  width: 73%;
  max-height: 81.3%;
  object-fit: cover;
  object-position: 0 0;
}
