#menu {
  display: none;
}

#menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  transition: left 1s;
  left: 100%;
  right: 0;
  background: var(--background2);
  padding: 0 2rem;
}

#menu-container._open {
  left: 0;
}

#menu-header {
  top: 0;
  padding: 2rem 0;
  height: var(--header-height);
}

#menu-content {
  padding-top: var(--header-height);
  max-width: var(--content-width);
  margin: auto;
}

.menu-nav {
  font-weight: 700;
  display: block;
  color: var(--text);
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

#menu-toggle {
  display: inline-block;
  position: absolute;
  right: 0;
  top: calc(var(--header-height) / 2);
  width: calc(var(--header-height) / 4);
  height: calc(var(--header-height) / 4);
  transform: translateY(-50%);
}

#menu-toggle:hover {
  cursor: pointer;
}

#menu-toggle span {
  display: block;
  position: absolute;
  height: calc(var(--header-height) / 20);
  width: 100%;
  background: var(--text);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#menu-toggle span:nth-child(1) {
  top: 0;
}

#menu-toggle span:nth-child(2),
#menu-toggle span:nth-child(3) {
  top: calc(var(--header-height) / 10);
}

#menu-toggle span:nth-child(4) {
  top: calc(var(--header-height) / 5);
}

#menu-toggle._open span:nth-child(1) {
  width: 0%;
  left: 50%;
}

#menu-toggle._open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#menu-toggle._open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#menu-toggle._open span:nth-child(4) {
  top: calc(var(--header-height) / 10);
  width: 0%;
  left: 50%;
}
