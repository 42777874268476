@media (max-width: 700px) {
  .carousel-container {
    padding: 0 2rem !important;
  }
  .carousel {
    margin: 0 2rem !important;
  }
}

.carousel-wrapper {
  overflow-x: hidden;
  margin: 0 -2rem;
}

.carousel-container {
  max-width: calc(var(--page-width));
  margin: 0 auto;
  padding: 0 4rem;
  position: relative;
}

.carousel {
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  grid-gap: 8rem;
  transition: transform 0.5s;
}

.carousel-button {
  position: absolute;
  z-index: 1;
  font-size: 0.5rem;
  border-radius: 2rem;
  height: 4rem;
  width: 4rem;
  mask-size: 50%;
  mask-repeat: no-repeat;
  mask-position: 50%;
  mask-image: url("../../utils/images/arrow.png");
  background: var(--text);
  top: 50%;
}

.carousel-button:hover {
  transition: mask-size 0.1s;
  cursor: pointer;
  mask-size: 60%;
}

.carousel-button._left {
  transform: translate(0, -50%) scaleX(-1);
  left: 0;
}

.carousel-button._right {
  transform: translate(0, -50%);
  right: 0;
}

.carousel-slide {
  user-select: none;
}
